
































































import Vue from 'vue';
import IpTableColumn from '@glittr/frontend-core/src/components/p-table/IpTableColumn';
import GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel from '@/src/services/viewModel/request/SiHaSafetyAnchorPoint/GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel';
import SiHaDocumentationResourceViewModel from '@/src/services/viewModel/resource/SiHaDocumentationResourceViewModel';
import SafetyAnchorPointListResourceViewModel from '@/src/services/viewModel/resource/SafetyAnchorPointListResourceViewModel';
import SafetyHookResourceViewModel from '@/src/services/viewModel/resource/SafetyHookResourceViewModel';

export default Vue.extend({
  auth: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    safetyHooks: [] as SafetyHookResourceViewModel[],
    errorResponse: undefined as any,
    columns: [] as IpTableColumn[],
    numberToMultiply: 1 as number | undefined,
    safetyAnchorPointList: [] as SafetyAnchorPointListResourceViewModel[],
    isLoading: false,
    safetyAnchorPointSelection: [] as any,
  }),
  computed: {
    siHaDocumentationId() {
      const id = this.$router.currentRoute?.params?.id as string | undefined;
      if (id) {
        return Number.parseInt(id, 10);
      }
      return undefined;
    },
    innerValue: {
      get(): SiHaDocumentationResourceViewModel {
        return this.value ?? {};
      },
      set(value: SiHaDocumentationResourceViewModel) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    this.columns = [
      {
        value: Vue.$t('page.safetyAnchorPointDetail.label.safetyAnchorPointNr'),
        field: 'name',
      },
      // TODO: Load Name of safetyhook
      {
        value: Vue.$t('page.safetyAnchorPointDetail.label.usedSafetyAnchorPoint'),
        field: 'safetyHookId',
        format: (id: number) => this.resolveSafetyHookName(id),
      },
    ];
    await this.loadSafetyHooks();
    await this.populateData();
  },
  methods: {
    resolveSafetyHookName(id: number) {
      const usedSH = this.safetyHooks.find((sh: SafetyHookResourceViewModel) => sh.id === id);
      return usedSH?.name ?? '...';
    },
    async loadSafetyHooks() {
      const response = await this.$service.api.siHaSafetyHook.getSafetyHookList();
      this.safetyHooks = response.items;
    },
    async deleteSafetyAnchorPoint(id: number) {
      this.isLoading = true;
      try {
        const wantsDelete = await this.$dialog.confirm(this.$t('app.confirmDelete'), this.$t('app.doYouWantToDelete'));
        if (wantsDelete) {
          await this.$service.api.siHaSafetyAnchorPoint.deleteSafetyAnchorPoint(id);
          await this.populateData();
        }
      } catch (error) {
        console.error(error);
        this.errorResponse = error;
      }
      this.isLoading = false;
    },
    async populateData() {
      this.isLoading = true;
      const request = new GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel();
      request.sihaDocumentationId = this.siHaDocumentationId;
      const list = await this.$service.api.siHaSafetyAnchorPoint.getSafetyAnchorPointsBySiHaDocumentation(request);
      this.safetyAnchorPointList = list.items;
      this.isLoading = false;
    },
    gotoSafetyAnchorPointDetail(id: number) {
      this.$router.push(`/glaromat/il/siha/documentation/${this.siHaDocumentationId}/safetyAnchorPoint/${id}`);
    },
    gotoNewSafetyAnchorPoint() {
      this.$router.push(`/glaromat/il/siha/documentation/${this.siHaDocumentationId}/safetyAnchorPoint/`);
    },
    async multiplySafetyAnchorPoints() {
      if (this.numberToMultiply && this.safetyAnchorPointSelection[0].id) {
        await this.$service.api.siHaSafetyAnchorPoint.multiplySafetyAnchorPoint(this.safetyAnchorPointSelection[0].id.value!, this.numberToMultiply!);
        this.safetyAnchorPointSelection = [];
        this.numberToMultiply = undefined as number | undefined;
        await this.populateData();
      }
    },
  },
});
